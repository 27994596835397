import {
  $currentDataWithoutFiltersStore,
  removeCurrentDataWithoutFilters,
  saveCurrentDataWithoutFilters,
  saveCurrentPaginationPageWithoutFilters,
} from '.';

$currentDataWithoutFiltersStore
  .on(saveCurrentDataWithoutFilters, (prev, { data, path }) => ({
    ...prev,
    [path]: { ...prev[path], data },
  }))
  .on(removeCurrentDataWithoutFilters, (prev, key) => ({
    [key]: prev[key],
  }))
  .on(saveCurrentPaginationPageWithoutFilters, (prev, { path, page }) => ({
    ...prev,
    [path]: { ...prev[path], page },
  }));
