import { createEvent, createStore } from 'effector';

import { TFilters } from '../types';

import { TCheckboxFiltersData, TSortingItems, TSwitchFiltersData } from '~utils';

export const $filtersStore = createStore<TFilters>({
  checkboxes: {
    brand: {},
    material: {},
    model: {},
    color: {},
    length: {},
    output: {},
    charging_type: {},
    connector_type: {},
  },
  switches: {
    is_new: false,
    is_hit: false,
    stock: false,
    discount: false,
  },
  price: {
    min: 0,
    max: 0,
  },
  maxFilterPrice: 0,
  switchQueries: '',
  priceQueries: '',
  filtersQueries: '',
  currSort: '-is_hit',
  isLabelRender: false,
  queries: null,
  currentPaginationPage: 1,
  checkedFilter: '',
});

export const updateCheckboxes = createEvent<{
  title: keyof TCheckboxFiltersData;
  key: string;
  value: boolean;
}>();
export const updateSwitches = createEvent<{ key: keyof TSwitchFiltersData; value: boolean }>();
export const removeFilters = createEvent<{ min: number; max: number }>();
export const updatePrice = createEvent<{ min: number; max: number }>();

export const setMaxFilterPrice = createEvent<number>();

export const saveSwitchQueries = createEvent<string>();
export const savePriceQueries = createEvent<string>();
export const saveFiltersQueries = createEvent<string>();
export const saveCurrSort = createEvent<TSortingItems>();
export const setIsLabelRender = createEvent<boolean>();
export const saveCurrentQueries = createEvent<string[]>();
export const saveCurrentPaginationPage = createEvent<number>();
export const saveCurrentFiltersImperative = createEvent<TFilters>();
export const saveCheckedFilter = createEvent<string>();
