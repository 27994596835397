import {
  $filtersStore,
  removeFilters,
  saveFiltersQueries,
  savePriceQueries,
  saveCurrSort,
  saveSwitchQueries,
  setIsLabelRender,
  setMaxFilterPrice,
  updateCheckboxes,
  updatePrice,
  updateSwitches,
  saveCurrentQueries,
  saveCurrentPaginationPage,
  saveCurrentFiltersImperative,
  saveCheckedFilter,
} from '.';

$filtersStore
  .on(updateCheckboxes, (prev, { title, key, value }) => ({
    ...prev,
    checkboxes: { ...prev.checkboxes, [title]: { ...prev.checkboxes[title], [key]: value } },
  }))
  .on(updateSwitches, (prev, { key, value }) => ({
    ...prev,
    switches: { ...prev.switches, [key]: value },
  }))
  .on(updatePrice, (prev, { min, max }) => ({
    ...prev,
    price: { min, max },
  }))
  .on(removeFilters, (prev, { min, max }) => ({
    ...prev,
    checkboxes: {
      brand: {},
      material: {},
      model: {},
      color: {},
      length: {},
      output: {},
      charging_type: {},
      connector_type: {},
    },
    switches: {
      is_new: false,
      is_hit: false,
      stock: false,
      discount: false,
    },
    price: {
      min: +min,
      max: +max,
    },
    switchQueries: '',
    priceQueries: '',
    filtersQueries: '',
    isLabelRender: false,
    queries: null,
    currentPaginationPage: 1,
  }))
  .on(setMaxFilterPrice, (prev, maxFilterPrice) => ({
    ...prev,
    maxFilterPrice,
  }))
  .on(saveSwitchQueries, (prev, switchQueries) => ({
    ...prev,
    switchQueries,
  }))
  .on(savePriceQueries, (prev, priceQueries) => ({
    ...prev,
    priceQueries,
  }))
  .on(saveFiltersQueries, (prev, filtersQueries) => ({
    ...prev,
    filtersQueries,
  }))
  .on(saveCurrSort, (prev, currSort) => ({
    ...prev,
    currSort,
  }))
  .on(setIsLabelRender, (prev, isLabelRender) => ({
    ...prev,
    isLabelRender,
  }))
  .on(saveCurrentQueries, (prev, queries) => ({
    ...prev,
    queries,
  }))
  .on(saveCurrentPaginationPage, (prev, currentPaginationPage) => ({
    ...prev,
    currentPaginationPage,
  }))
  .on(saveCheckedFilter, (prev, checkedFilter) => ({
    ...prev,
    checkedFilter,
  }))
  .on(saveCurrentFiltersImperative, (_, data) => ({
    ...data,
  }));
