import clsx from 'clsx';
import { useStore } from 'effector-react';
import { FC, useEffect, useMemo, useRef, useState } from 'react';

import { useLocation, useParams } from 'react-router-dom';

import styles from './catalog-children-page.module.scss';
import { ICatalogChildrenPageProps } from './types';

import { CatalogItems, ItemsWithFilters, PageWrapperWithCommonBlocks } from '../../components';
import { Breadcrumbs, Loader } from '../../components/ui';

import { ItemDetailsPage } from '../item-details-page';

import { $currentDataStore, removeCurrentData } from 'src/models';
import { TCategory, getCategory } from '~utils';

export const CatalogChildrenPage: FC<ICatalogChildrenPageProps> = ({ className = '', ...rest }) => {
  const [data, setData] = useState<TCategory | null>(null);
  // раньше id получал напрямую в ItemsWithFilters, но в этом случае при возврате через крошки в каталог
  // в урле менялся id, а дата здесь не успевала обновиться, и поэтому из компонента шёл запрос за
  // товарами, который закономерно возвращал [].
  const [currRenderId, setCurrRenderId] = useState<string | null>(null);
  const { pathname } = useLocation();
  const { id } = useParams();

  const { data: prevData } = useStore($currentDataStore);

  // если есть categoryName, значит речь идёт о категории. Если нету, значит это конкретный товар
  const [currId, categoryName] = useMemo(() => id?.split('_') ?? [], [id]);

  // для того, чтобы после возвращения в каталог из просмотра товаров, не запрашивать данные заново
  const prevId = useRef(currId);

  useEffect(() => {
    if (categoryName) {
      if (currId !== prevId.current) {
        removeCurrentData();
      }
      prevId.current = currId;
    }
  }, [currId, categoryName]);

  useEffect(() => {
    if (!categoryName || prevData) return;

    if (currId) {
      setData(null);

      getCategory(currId)
        .then(({ data }) => {
          setCurrRenderId(data.id.toString());
          setData(data);
        })
        .catch(err => console.log(err));
    }
  }, [currId, categoryName, prevData]);

  if (categoryName && !data) return <Loader />;

  return (
    <section className={clsx(styles.container, className)} {...rest}>
      <Breadcrumbs isProduct={!categoryName} />
      {!categoryName ? (
        <ItemDetailsPage className={styles.item_page} />
      ) : data?.children.length ? (
        <CatalogItems data={data.children} prefixUrl={pathname} />
      ) : (
        currRenderId && (
          <PageWrapperWithCommonBlocks className={styles.wrapper}>
            <ItemsWithFilters title={data?.name ?? ''} categoryId={currRenderId} />
          </PageWrapperWithCommonBlocks>
        )
      )}
    </section>
  );
};
