import {
  $currentDataStore,
  removeCurrentData,
  removeCurrentFilters,
  saveCurrentData,
  saveCurrentFilters,
} from '.';

$currentDataStore
  .on(saveCurrentData, (prev, data) => ({
    ...prev,
    data,
  }))
  .on(saveCurrentFilters, (prev, filters) => ({
    ...prev,
    filters,
  }))
  .on(removeCurrentData, () => ({
    data: null,
    filters: null,
  }))
  .on(removeCurrentFilters, prev => ({
    ...prev,
    filters: null,
  }));
