import { createEvent, createStore } from 'effector';

import { TItemsWithPagination } from '~utils';

type TStore = Record<string, { data: TItemsWithPagination | null; page: number }>;

export const $currentDataWithoutFiltersStore = createStore<TStore>({});

export const saveCurrentDataWithoutFilters = createEvent<{
  data: TItemsWithPagination | null;
  path: string;
  page?: number;
}>();
export const removeCurrentDataWithoutFilters = createEvent<string>();
export const saveCurrentPaginationPageWithoutFilters = createEvent<{
  path: string;
  page: number;
}>();
