import { createEvent, createStore } from 'effector';

import { TFilters } from '../types';

import { TItemsWithPaginationAndFilters } from '~utils';

type TStore = {
  data: TItemsWithPaginationAndFilters | null;
  filters: TFilters | null;
};

export const $currentDataStore = createStore<TStore>({
  data: null,
  filters: null,
});

export const saveCurrentData = createEvent<TItemsWithPaginationAndFilters | null>();
export const saveCurrentFilters = createEvent<TFilters | null>();
export const removeCurrentData = createEvent();
export const removeCurrentFilters = createEvent();
