import clsx from 'clsx';
import { FC, useEffect } from 'react';

import styles from './catalog-page.module.scss';
import { ICatalogPageProps } from './types';

import { CatalogMain } from '../../components';

import { Breadcrumbs } from '../../components/ui';

import { removeCurrentData, saveCurrentPaginationPage } from 'src/models';

export const CatalogPage: FC<ICatalogPageProps> = ({ className = '', ...rest }) => {
  useEffect(() => {
    removeCurrentData();
    saveCurrentPaginationPage(1);
  }, []);

  return (
    <section className={clsx(styles.container, className)} {...rest}>
      <Breadcrumbs />
      <CatalogMain />
    </section>
  );
};
